.empty-editor {
    padding: 0px 10px;
}

.empty-editor h1 {
    color: #333333;
}

.empty-editor p {
    color: #666666;
}