.cols-selector {
    display: flex;
    box-sizing: border-box;
    gap: 4px;
    padding: 3px;
    font-size: 13px;
    background-color: white;
    border-radius: 10px;
    position: relative;
    z-index: 3;
    user-select: none;
}

.cols-selector button {
    appearance: none;
    border: none;
    padding: 0 3px;
    background: none;
    font: inherit;
    transition: color 0.33s;
}

.cols-selector button:hover {
    color: dodgerblue;
}

.col-current {
    padding: 0 3px;
    font-weight: 800;
    color: dodgerblue;
}