.editor {
    flex: 1;
    position: relative;
    display: flex;
    overflow: hidden;
}

.editor-clip {
    flex: 1;
    margin-left: -5px;
}

.delete-button {
    position: absolute;
    right: 1px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    z-index: 2;
    border: none;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.33s;
}

.delete-button:hover {
    transform: scale(1.1);
    background-color: skyblue;
}

.delete-button:active {
    transform: scale(0.95);
    background-color: skyblue;
}