.grid {
    display: grid;
    gap: 6px;
    grid-auto-columns: 60px;
    grid-auto-rows: 60px;
    padding: 2px;
}

.grid-container {
    position: relative;
    overflow: hidden;
}

.grid-page {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: 0.5s opacity, 0.5s transform;
    width: 64px;
    height: 96px;
    color: #333;
    font-size: 20px;
    z-index: 3;

    margin: -48px -32px;
    pointer-events: none;
    background: radial-gradient(ellipse, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8) 25%, rgba(255,255,255,0.4) 40%, rgba(255,255,255,0) 66%);
}

.grid-page-left {
    top: 50%;
    transform: translate(7px, 0px);
}

.grid-page-right {
    top: 50%;
    right: 0%;
    transform: translate(-7px, 0px);
}

.hidden {
    opacity: 0;
}

.grid-page-left.hidden {
    transform: translate(20px, 0px);
}

.grid-page-right.hidden {
    transform: translate(-20px, 0px);
}