@keyframes block-select {
    from {
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 8px;
        outline-width: 5px;
    }
    to {
        outline-color: slategray;
        outline-offset: 0px;
        outline-width: 1px;
    }
}

.grid-content {
    background-color: skyblue;
    transition: 0.5s background-color, 0.33s filter;
    border-radius: 6px;
    display: flex;
    user-select: none;

    color: white;
    font-size: 32px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.grid-content-selected {
    outline-color: slategray !important;
    outline-style: solid;
    animation-name: block-select;
    animation-duration: 0.33s;
    animation-iteration-count: 1;
    background-color: deepskyblue;
}

.grid-content:hover {
    background-color: deepskyblue;
}

.grid-content-grab {
    position: absolute;
}

.grid-content-grab-left {
    width: 10px;
    height: 100%;
    left: 0;
    top: 0;
    margin-left: -3px;
    cursor: ew-resize;
}

.grid-content-grab-right {
    width: 10px;
    height: 100%;
    left: 100%;
    top: 0;
    margin-left: -7px;
    cursor: ew-resize;
}

.grid-content-grab-top {
    width: 100%;
    height: 10px;
    left: 0;
    top: 0;
    margin-top: -3px;
    cursor: ns-resize;
}

.grid-content-grab-bottom {
    width: 100%;
    height: 10px;
    left: 0;
    top: 100%;
    margin-top: -7px;
    cursor: ns-resize;
}