.app {
  display: flex;
}

.app-grid {
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1;
  align-self: center;
  margin-right: 5px;
}